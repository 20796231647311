import * as React from 'react'
const styles = require('scss/article.module.scss')

export type ArticleProps = {
  headerContents: JSX.Element
  children: React.ReactChild
}

export const Article = ({
  headerContents,
  children,
}: ArticleProps): JSX.Element => {
  return (
    <article>
      <div>{headerContents}</div>
      <div className={styles.contents}>
        <section>{children}</section>
      </div>
    </article>
  )
}
