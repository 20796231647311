import * as React from 'react'
import Header from '../../components/organisms/Header'
import Footer from '../../components/organisms/Footer'
import { Link } from 'gatsby'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Article } from '../../components/organisms/Article'
import HeadingIllust from '../../components/atoms/HeadingIlust'
import { Form } from '../../components/organisms/Form'
const styles = require('scss/news.module.scss')

const headImage = require('images/news_0.svg')

const header1 = (
  <>
    <h2>紹介制度のお知らせ</h2>
    <p>
      弊社は長所を活かす経営をモットーとしておりますが、現在は技術力に特化しており、営業に長けた人材が不足しております。
      そこで新規顧客開拓のため、弊社では営業パートナーを募集します。
      弊社に顧客をご紹介いただき、成約した場合には
      <strong>契約金額の20%</strong>
      をお支払いさせて頂きます！
    </p>
  </>
)

const body1 = (
  <div>
    <section>
      <h3>■ お支払い条件</h3>
      <ul>
        <li>
          ・弊社から紹介者へ別途営業の委託をしていないこと
        </li>
        <li>・紹介から3ヶ月以内の契約締結であること</li>
        <li>・紹介先企業と弊社との直接契約であること</li>
        <li>
          ・紹介先企業と弊社が過去に契約実績がないこと
        </li>
      </ul>
    </section>
    <section>
      <h3>■ お支払日</h3>
      <p>全ての契約金額が入金された翌月末</p>
    </section>
    <section>
      <h3>■ お支払金額</h3>
      <p>
        <strong>契約金額の20%</strong>
        <br />※ 月額契約の場合は6ヶ月分を上限とする
      </p>
      <p>
        例
        <ul>
          <li className={styles.mb3}>
            {`① 500万円の契約の場合
    500万円 × 20% = 100万円`}
          </li>
          <li className={styles.mb3}>
            {`② 月額100万円で3ヶ月の契約の場合
    100万円 × 20% × 3ヶ月 = 60万円`}
          </li>
          <li className={styles.mb3}>
            {`③ 月額100万円で12ヶ月の契約の場合
    100万円 × 20% × 6ヶ月 = 120万円`}
          </li>
        </ul>
      </p>
    </section>
    <section>
      <h3>■ お問い合わせ</h3>
      <p>
        {`下記のフォームよりご連絡ください。
2営業日以内にご連絡させて頂きます。`}
      </p>
    </section>
  </div>
)
const actions = (
  <div className={styles.actions}>
    <Link to="/">
      <div className={styles.link}>
        <FontAwesomeIcon
          className="icon"
          icon={['fas', 'angle-left']}
        />
        <span>トップページへ戻る</span>
      </div>
    </Link>
  </div>
)
const News = () => {
  return (
    <section className={styles.news}>
      <Header />
      <div>
        <HeadingIllust
          image={headImage}
          title="ニュース"
          pageName="News"
        />
      </div>
      <div className={styles.newsBody}>
        <div className={styles.margin} />
        <div className={styles.main}>
          <div className={styles.articleWrapper}>
            <Article headerContents={header1}>
              {body1}
            </Article>
          </div>
        </div>
      </div>
      <Form />
      <Footer />
    </section>
  )
}

export default News
